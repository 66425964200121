import { Controller } from "@hotwired/stimulus"
import $ from "jquery"
import moment from "moment"
export default class extends Controller {

  connect() {

  }

  change(event) {
    const $target = $(event.target);
    const $frame = $("#" + $target.data("frame_id"));
    const protocolId = $target.data("protocol_id");
    const cageID = $("#cage_split_cage_id").val();

    let src = $frame.data("src") + "?cage_card_template_class=" + $target.val() + "&protocol_id=" + protocolId;

    if (cageID) {
      src += "&cage_id=" + cageID;
    }

    const formData = this.getFormData();
    $frame.attr("src", src);

    $frame.on("turbo:frame-load", () => {
      this.populateFormData(formData);
    });
  }

  getFormData() {
    const data: Record<string, string> = {};

    let sex = "female";
    const prefixes = new Set();
    $("[data-populate]").each((_, element) => {
      const input = $(element);
      const name = input.attr("name");

      if (name) {
        if (name.includes("[sex]")) {
          const value = input.val();
          sex = ["m", "male"].includes(value.toString().toLowerCase()) ? "male" : "female";
        }
        prefixes.add(name.replace(/\[[^\\[]*\]$/, ''));
        data[name] = input.val();
      }
    });

    prefixes.forEach(prefix => {
      data[`${prefix}[mid]`] = $('[data-populate][name$="[mid]"]').val() || (
        sex === "male"
          ? $('[data-populate][name$="[strain_name]"]').val() || $('[data-populate][name$="[strain_code]"]').val()
          : ""
      );
      data[`${prefix}[fid]`] = $('[data-populate][name$="[fid]"]').val() || (
        sex === "female"
          ? $('[data-populate][name$="[strain_name]"]').val() || $('[data-populate][name$="[strain_code]"]').val()
          : ""
      );
      data[`${prefix}[mdob]`] = $('[data-populate][name$="[mdob]"]').val() || (
        sex === "male"
          ? this.parseDate($('[data-populate][name$="[dob]"]').val() as string)
          : ""
      );
      data[`${prefix}[fdob]`] = $('[data-populate][name$="[fdob]"]').val() || (
        sex === "female"
          ? this.parseDate($('[data-populate][name$="[dob]"]').val() as string)
          : ""
      );
    });

    return data;
  }

  populateFormData(formData: Record<string, string>) {
    $.each(formData, function (name, value) {
      const input = $(`[name="${name}"]`);
      if (input.length) {
        input.val(value);
      }
    });
  }

  parseDate(dateString: string): string | null {
    if (!dateString) return null;

    const normalized = dateString.replace(/[-.]/g, "/").trim();

    const possibleFormats = [
      "DD/MM/YYYY", "D/M/YYYY", "DD-MM-YYYY", "D-M-YYYY", "DD/MM/YY", "D/M/YY", "DD-MM-YY", "D-M-YY",

      "YY/MM/DD", "YY-M-D", "YY/MM/D", "YY/M/DD", "YYYY/MM/DD", "YYYY-M-D", "YYYY/MM/D", "YYYY/M/DD",

      "MM/DD/YYYY", "M/D/YYYY", "MM-DD-YYYY", "M-D-YYYY", "MM/DD/YY", "M/D/YY", "MM-DD-YY", "M-D-YY"
    ];

    for (const format of possibleFormats) {
      const parsedDate = moment(normalized, format, true);
      if (parsedDate.isValid()) {
        return parsedDate.format("YYYY-MM-DD");
      }
    }

    return null;
  }
}
